import { BackButton, DeleteButton, NextButton, PrevButton, StyledContent, StyledImage } from './styledComponents';
import type { TFullScreenGalleryProps, TSliderItem } from '../../types';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import { useEffect, useMemo, useRef, useState } from 'react';

import { ComplainIcon } from '../ComplainIcon/ComplainIcon';
import { Dialog } from '@mui/material';
import { HideIcon } from '../HideIcon/HideIcon';
import { LikeIcon } from '../LikeIcon/LikeIcon';
import { PhotoShareIcon } from '../PhotoShareIcon/PhotoShareIcon';
import Slider from 'react-slick';
import bg from '../../../../../assets/images/dashboard/photoBG.png';
import { useData } from './hooks/useData';
import { useHandlers } from './hooks/useHandlers';
import { useRecoilState } from 'recoil';
import { userRoleState } from '../../../../../utils/atoms';

const defaultSliderSettings = {
  swipe: true,
  arrows: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  speed: 200,
  infinite: false,
  lazyLoad: 'progressive' as 'progressive',
  style: { backgroundImage: `url(${bg})` },
};

export function FullScreenGallery({
  currentImage,
  setCurrentImage,
  gallery,
  onDelete,
  setCurrentSlide,
  setGalleryItems,
  hasDeleteIcon,
  setFeedValue,
  needToShowLikes,
  isItFeed,
  onRefresh,
}: TFullScreenGalleryProps) {
  const [scale, setScale] = useState(1);
  const [, index] = currentImage;
  const [slideIndex, setSlideIndex] = useState(index);
  const [userRole] = useRecoilState(userRoleState);
  const sliderRef = useRef<Slider | null>(null);
  const imageList = gallery.filter((item: TSliderItem) =>
    !!setFeedValue ? !!item.is_shared : !item.isItDefault && !!item.URL,
  );

  const panningProps = {
    disabled: scale === 1,
  };
  const { onCloseGallery, onDeleteImage, onZoomChange, setNextSlide, afterDelete, onImageClick } = useHandlers({
    setCurrentImage,
    setGalleryItems,
    gallery,
    onDelete,
    imageList,
    currentImage,
    setScale,
    sliderRef,
    setCurrentSlide,
    setSlideIndex,
    slideIndex,
    itsFeed: isItFeed || !!setFeedValue,
  });

  const { likes, photo_id, is_liked, is_shared, is_owner, URL, is_hidden } = useData(currentImage, gallery);

  const isHideIconShown = useMemo(() => {
    return userRole.is_superuser && !is_owner;
  }, [is_owner, userRole.is_superuser]);

  const sliderSettings = useMemo(() => {
    const hasMoreThanOne = imageList.length > 1 && !setFeedValue && !isItFeed;
    return { ...defaultSliderSettings, swipe: hasMoreThanOne, arrows: hasMoreThanOne };
  }, [imageList.length, isItFeed, setFeedValue]);

  useEffect(() => {
    window.history.pushState(null, '', window.location.href);

    const handlePopState = () => {
      onCloseGallery();
    };

    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [onCloseGallery]);

  return (
    <Dialog fullScreen open={!!currentImage.length}>
      <BackButton onClick={onCloseGallery} />
      {!!hasDeleteIcon && <DeleteButton onClick={onDeleteImage} />}
      {needToShowLikes && (
        <>
          <LikeIcon
            likesCount={likes ?? 0}
            photoId={photo_id!}
            isLikedByUser={is_liked ?? false}
            size={40}
            bottom={16}
            left={16}
            setGalleryItems={setGalleryItems}
            setFeedValue={setFeedValue}
          />
          {!!is_owner && (
            <PhotoShareIcon
              photoId={photo_id!}
              isShared={is_shared!}
              size={40}
              bottom={16}
              right={16}
              setGalleryItems={setGalleryItems}
              setFeedValue={setFeedValue}
              onCloseGallery={onImageClick}
              onRefresh={onRefresh}
              isHidden={is_hidden}
            />
          )}
          {!is_owner && <ComplainIcon photoLink={URL} size={40} top={16} right={16} borderRadius={32} />}
        </>
      )}
      {isHideIconShown && (
        <HideIcon
          photoId={photo_id ?? 0}
          size={40}
          bottom={16}
          right={16}
          afterDelete={afterDelete}
          setContent={setFeedValue}
          onCloseGallery={onImageClick}
          onRefresh={onRefresh}
        />
      )}
      <Slider
        ref={sliderRef}
        {...sliderSettings}
        initialSlide={index}
        prevArrow={<PrevButton disabled={!slideIndex} />}
        nextArrow={<NextButton disabled={imageList.length - 1 === slideIndex} />}
        beforeChange={setNextSlide}>
        {imageList.map((item, slideIndex) => {
          return (
            <TransformWrapper
              disablePadding
              key={slideIndex}
              initialScale={1}
              onZoom={onZoomChange}
              panning={panningProps}>
              <TransformComponent>
                <StyledContent onClick={onImageClick}>
                  <StyledImage src={item.URL as string} />
                </StyledContent>
              </TransformComponent>
            </TransformWrapper>
          );
        })}
      </Slider>
    </Dialog>
  );
}
