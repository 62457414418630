import { AbsoluteRoutes } from '../../../../utils/enums';
import { LocalStorageService, SessionStorageService } from '../../../../utils/services';
import type { TUseErrorPageHandlers } from '../types';
import { useCallback } from 'react';

export function useErrorPageHandlers({ errorType, onReset }: TUseErrorPageHandlers) {
  const handleButtonClick = useCallback(() => {
    if (errorType && !!(window.history && window.history.replaceState)) {
      window.history.go();
    } else {
      window.history.pushState({}, '', AbsoluteRoutes.DashboardMain);
      window.history.go();
    }
  }, [errorType]);

  const handleRetry = useCallback(() => {
    onReset?.();
  }, [onReset]);

  const reloadPage = useCallback(() => {
    if (!!(window.history && window.history.replaceState)) {
      window.history.go();
    } else {
      document.location.reload();
    }
  }, []);

  const logOut = useCallback(() => {
    LocalStorageService.clear();
    SessionStorageService.clear();
    document.cookie = 'token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
    reloadPage();
  }, [reloadPage]);

  return { handleButtonClick, handleRetry, reloadPage, logOut };
}
