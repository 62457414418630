import { Box, Button, Typography, useTheme } from '@mui/material';
import { PageLoader } from '../../../shared';
import { RequestState } from '../../../../../utils/enums';
import { SliderImage } from '../../../shared/components/SliderImage/SliderImage';
import deleteIcon from '../../../../../assets/images/dashboard/delete_pet_icon.svg';
import hideIcon from '../../../../../assets/images/close_pass_blue.svg';
import saveIcon from '../../../../../assets/images/save_icon.svg';
import unHideIcon from '../../../../../assets/images/open_pass.svg';
import { useUserManipulation } from '../../../../../utils/hooks';
import { useTranslation } from 'react-i18next';

export function DeleteButton({
  initialHideStatus,
  onSaveClick,
}: {
  initialHideStatus: boolean;
  onSaveClick: () => void;
}) {
  const theme = useTheme();
  const { t } = useTranslation();
  const { userRequestState, handleDeleteUser, handleChangeUserStatus, handleChangeUserPassword, isUserHidden } =
    useUserManipulation({
      initialHideStatus,
    });
  return (
    <Box width='100%' display='flex' gap={2} justifyContent='space-between' alignItems='center'>
      <Box display='flex' gap={4} alignItems='center' flex='0 0 25%'>
        <SliderImage url={deleteIcon} imageSize={32} onImgClick={handleDeleteUser} variant='circular' />{' '}
      </Box>
      <Button variant='primaryContained' onClick={handleChangeUserPassword}>
        <Typography
          color={theme.palette.text.primary}
          fontSize={16}
          fontFamily='Nunito'
          textAlign='center'
          onClick={handleChangeUserPassword}>
          {t('changePassword.title')}
        </Typography>
      </Button>
      <Box display='flex' gap={4} alignItems='center' flex='0 0 25%' justifyContent='flex-end'>
        <SliderImage
          url={isUserHidden ? unHideIcon : hideIcon}
          imageSize={48}
          onImgClick={handleChangeUserStatus}
          variant='circular'
        />
        <SliderImage url={saveIcon} imageSize={32} onImgClick={onSaveClick} variant='circular' />
      </Box>
      {userRequestState === RequestState.Pending && <PageLoader />}
    </Box>
  );
}
