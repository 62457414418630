import { AppModules } from '../../../../../utils/const';
import { LocalStorageService, SessionStorageService } from '../../../../../utils/services';
import { ProfileRoutePath } from '../../../profile/utils/const/route-path';
import type { TSidebarHandlersArgs } from '../../types';
import { useCallback } from 'react';
import { useHistory } from 'react-router';

export function useHandlers({ setIsOpen, user, setRedirect }: TSidebarHandlersArgs) {
  const history = useHistory();

  const hollowClickHandler = useCallback(() => setIsOpen(false), [setIsOpen]);

  /* -------------------------------------------- */
  /* Should be refactored */
  const linkClickHandler = useCallback(
    () => setRedirect(() => `/${AppModules.profile}/${ProfileRoutePath.EDIT}`),
    [setRedirect],
  );

  const avatarClickHandler = useCallback(() => {
    history.push({
      pathname: `/${AppModules.profile}/${ProfileRoutePath.MAIN}`,
      state: { user },
    });
  }, [history, user]);

  const expandedClickHandler = useCallback(
    (): void => setRedirect((): string => `/${AppModules.profile}/${ProfileRoutePath.LANGUAGE}`),
    [setRedirect],
  );

  const toSupportRequest = useCallback(
    (): void => setRedirect((): string => `/${ProfileRoutePath.SUPPORT}`),
    [setRedirect],
  );
  /* -------------------------------------------- */

  const logout = useCallback((): void => {
    LocalStorageService.clear();
    SessionStorageService.clear();
    document.cookie = 'token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
    history.push('/');
  }, [history]);

  const cropLongName = useCallback((longName: string): string => {
    if (longName.length > 15) {
      return `${longName.slice(0, 15)}...`;
    } else {
      return longName;
    }
  }, []);

  return {
    hollowClickHandler,
    logout,
    cropLongName,
    avatarClickHandler,
    linkClickHandler,
    expandedClickHandler,
    toSupportRequest,
  };
}
